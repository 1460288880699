/* eslint-disable react/prop-types */

function Spinner(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
      
      }}
    >
      <div className="spinner-border text-green  font-md" role="status"></div>
      <div className="mt-2" style={{fontSize: `${props.size}px`}}>{props.msg || "Loading..."}</div>    </div>
  );
}

export default Spinner;
