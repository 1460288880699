import { useState } from "react";
import { navigationLink } from "../../data/data";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";

const SidebarMobile = (props) => {
  const [activeLinkIdx, setActiveLinkIdx] = useState(0);

  const handleNavLinkClick = (index) => {
    setActiveLinkIdx(index);
  };

  return (
    <div className="sidebars">
      <nav className="navigations">
        <ul className="nav-list p-0">
          {navigationLink.map((link, index) => {
            const isActive = index === activeLinkIdx;
            return (
              <li className="nav-items" key={link.id}>
                <NavLink
                  to={link.path}
                  className={`nav-link ${isActive ? "active" : ""}`}
                  onClick={() => handleNavLinkClick(index)}
                >
                  <link.image
                    size={30}
                    color={isActive ? "blue" : "white"}
                    className="nav-link-icon"
                    alt={link.title}
                  />
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default SidebarMobile;
