/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';

const AutoSubmitInput = ({ value, onChange, onSubmit, delay = 10000, placeholder }) => {
  const [inputValue, setInputValue] = useState(value);
  const [message, setMessage] = useState('');
  const timeoutRef = useRef(null);

  const handleChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    onChange({ target: { name: 'code', value } });

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleSubmit(value);
    }, delay);
  };

  const handleSubmit = async (val) => {
    if (val.trim()) {
      try {
        const response = await onSubmit({ code: val });
        if (response.exists) {
          setMessage('Product code already exists. Please use a different code.');
        } else {
          setMessage('Product code is available.');
        }
      } catch (error) {
        setMessage('Error checking code.');
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="form-control"
      />
      {message && <div className="feedback-message text-danger">{message}</div>}
    </div>
  );
};

export default AutoSubmitInput;
