/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../utils/config";
import UserSessionContext from "../../context/UserSessionContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import './Login.css';

// Importing the useDebounce hook
import { useDebounce } from "../../utils/Hooks/useDebounce";
import AutoSubmitInput from "../../utils/AutoSubmitInput";

const SignUp = ({ setSignUp }) => {
  const [companyName, setCompanyName] = useState("");
  const [username, setUserName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const userSessionContext = useContext(UserSessionContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const navigate = useNavigate();


  
  const goToLogin = () => {
    navigate("/login");
   
  };
  const checkPhoneNumberExists = async (code) => {
    try {
      const response = await axios.post(`${config}/User/checkPhoneNumber`,  { phoneNo:number });
      return response.data;
    } catch (error) {
      console.error("Error checking code:", error);
      return { exists: false };
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPhoneValid) {
      return;
    }

    const _data = {
      "companyName": companyName,
      "phoneNo": number,
      "address": address,
      "email": email,
      "businessType": "R",
      "firstName": firstName,
      "lastName": lastName,
      "username": firstName,
      "password": password,
      "businessCode": "DEMO",
    };

    axios
      .post(`${config}/User/userRegister`, _data, {
        headers: { Authorization: userSessionContext.token },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success("Redirecting To Login Page");
          // userSessionContext.handleLogOut();
          goToLogin();
          setSignUp(false);
        } else if (res.data.status_code === 401) {
          userSessionContext.handleLogOut();
        } else if (res.data.status_code === 400) {
          toast.warn(res.data.msg);
        } else {
          toast.error("Warning");
        }
      })
      .catch((err) => {
        toast.error("Error ");
      });
  };

  return (
    <>
      <div className="sign_up_form">
        <form onSubmit={handleSubmit}>
          <h2 style={{ width: "100%", textAlign: "center", color: 'white' }}>Register</h2>
          <p className="p-0 m-0" style={{ width: "100%", textAlign: "center", color: 'white' }}>
            Create your account. It's free and only takes a minute.
          </p>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="col">
                {/* <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  placeholder="Mobile number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required
                /> */}
   <AutoSubmitInput
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  onSubmit={ checkPhoneNumberExists }
                  placeholder={'Enter Phone Number'}
                  delay={10000}
                />

              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                minWidth: "100%",
                outline: "none !important",
                textAlign: "center",
              }}
              className="btn btn-success btn-lg btn-block"
            >
              Register Now
            </button>
          </div>
        </form>
        <div onClick={() => setSignUp(false)} className="text-center text-white">
          Already have an account? <a href="#">Sign in</a>
        </div>
      </div>
    </>
  );
};

export default SignUp;
