import { createContext } from "react";

const CompanyContext = createContext({
  companies: "",
  company:"",
  fiscal:"",
  pos:'',
  setPos:() => {},
  soundE:"",
  flags:'',
  printerSize:'',
  setPrinterSize:() =>{},
  setFlags:() => {},
  setSoundE:() => {},
  setCompany: () => {}
});

export default CompanyContext;
