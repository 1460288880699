import React, { useState,useContext } from "react";



import axios from "axios";

import { Button, Col, Form, Row } from "react-bootstrap";
import UserSessionContext from "../../context/UserSessionContext";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import CompanyContext from "../../context/CompanyContext";

function ChangePassword() {
  const userSessionContext = React.useContext(UserSessionContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const companyContext = useContext(CompanyContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (e) => {
    const id = parseInt(sessionStorage.getItem("id"));
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password don't match.");
      return;
    }
    const req_data = {
      password: currentPassword,
      password2: confirmPassword,
    };
    axios
      .put(`${config}/Login/setPassword/${companyContext.company.user_id}`, req_data, {
        headers: {
          Authorization: userSessionContext.token,
        },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success(res.data.msg);
          userSessionContext.handleLogOut();
        } else if (res.data.status_code === 401) {
          userSessionContext.handleLogOut();
        } else if (res.data.status_code === 400) {
         
            toast.warn(res.data.msg);

        }
      })
      .catch((err) => {
      toast.error("Something went Wrong");
      });
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError("");
  };
  return (


    <Form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <Form.Group controlId="firstName">
          
          <Form.Label>Current Password :</Form.Label>
          <Form.Control
            label="Current Password"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            size="small"
            style={{
              borderRadius: "5px",
              paddingLeft: "10px",
              border:'1px solid black'

            }}
          />
          

</Form.Group>
<Form.Group controlId="lastName">
               
<Form.Label>New password:</Form.Label>
        <Form.Control
         id="outlined-adornment-password"
         type={showPassword ? "text" : "password"}
         value={newPassword}
         onChange={(e) => setNewPassword(e.target.value)}
         style={{

           borderRadius: "5px",
           paddingLeft: "10px",
           border:'1px solid black'
         }}
         label="Password"
        />
       
       </Form.Group>
       <Form.Group controlId="address1">
      <Form.Label>confirm password:</Form.Label>
        <Form.Control
       id="outlined-adornment-password"
       type={showPassword ? "text" : "password"}
       value={confirmPassword}
       style={{

         borderRadius: "5px",
         paddingLeft: "10px",
         border:'1px solid black'

       }}
       required
       onChange={(e) => setConfirmPassword(e.target.value)}
       label="Password"
    
        />
      
         </Form.Group>
       
        
         
      
      </Col>
      <Col xs={4}>
    
      
      </Col>
    
    </Row>
    
    <div
      className="mt-3"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
  
 
        <Button  variant="success"  type="submit" >
          Submit
        </Button>
       

    </div>
  </Form>



   
  );
}

export default ChangePassword;
