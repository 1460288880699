/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { iconsImgs, personsImgs } from "../../utils/images";
import { navigationLinks } from "../../data/data";
import "./Sidebar.css";
import { useContext } from "react";
import { SidebarContext } from "../../context/sidebarContext";
import { Link, NavLink } from "react-router-dom";


import UserSessionContext from "../../context/UserSessionContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const Sidebar = (props) => {
  const { setPos, pos,sidebarx } = props;
  const [activeLinkIdx] = useState(0);
  const userSessionContext = useContext(UserSessionContext);
  const [sidebarClass, setSidebarClass] = useState("");
  const { isSidebarOpen } = useContext(SidebarContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const handleicon = (navigationLink) => {
    if (navigationLink.title === "POS") {
      if (isSidebarOpen == false) {
        setPos(true);
        toggleSidebar();
      }
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      setSidebarClass("sidebar-change");
    } else {
      setSidebarClass("");
    }
  }, [isSidebarOpen]);



  return (
    <div className={` sidebar ${sidebarClass} ${sidebarx===true? 'hello':''}`}>
      <div className="user-info">
        <div className="info-img img-fit-cover">
          <img src={iconsImgs.man} alt="profile image" />
        </div>
      </div>

      <nav className="navigation">
        <ul className="nav-list p-0">
          {navigationLinks.map((navigationLink) => {
            return (
              <li className="nav-item " key={navigationLink.id}>
                <NavLink
                  to={navigationLink.path}
                  className={`nav-link ${
                    navigationLink.id === activeLinkIdx ? "active" : null
                  }  pt-2  `}
                  style={{ display: "flex" }}
                >
  <OverlayTrigger
                    key='bottom'
                    placement='right'
                    overlay={<Tooltip id={`tooltip-bottom`}>{navigationLink.title}</Tooltip>}
                    >
                   <img
                    src={navigationLink.image}
                    style={{ width: "40px" }}
                    className={`nav-link-icon ${
                      navigationLink.id === activeLinkIdx ? "active" : null
                    }    `}
                    alt={navigationLink.title}
                    onClick={() => {
                      handleicon(navigationLink);
                      setPos(false);
                    }}
                  /></OverlayTrigger>

                 
                  <p className=" p-0 my-0  navigation-title" style={{ color: "white",paddingLeft:'18px' }}>{navigationLink.title}</p>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
