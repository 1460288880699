import { useState, useRef, useEffect } from "react";
import "./Styles.css"; // Import your CSS file

const PIN_CODE = "1593"; // Correct pin code

function PassCode() {
  const [input, setInput] = useState("");
  const correctPin = "1593";
  const [clickedNumber, setClickedNumber] = useState(null);

  const handleNumberClick = (number) => {
    const newInput = input + (number + 1);
    setInput(newInput);
    setClickedNumber(number);

    if (newInput.length >= 4) {
      if (newInput !== correctPin) {
        setTimeout(() => {
          setInput("");
          setClickedNumber(null);
        }, 900);
      } else {
        setTimeout(() => {
          setInput("");
          setClickedNumber(null);
        }, 900);
      }
    }
  };
  const handleClearClick = () => {
    setClickedNumber("Clear");
    setInput("");
    // setClickedNumber(null);
  };
  const handleEnterClick = () => {
    if (input === correctPin) {
      setClickedNumber("Enter");
      setInput("");
    } else {
      setClickedNumber("Enter");
      setInput("");
      // setClickedNumber(null);
    }
  };
  return (
    <div id="pin" className="pinpad">
      <div className="dots">
        {Array(4)
          .fill()
          .map((_, index) => (
            <div
              key={index}
              className={`dot ${input.length > index && "active"}`}
            ></div>
          ))}
      </div>
      <p style={{ color: "white" }}>Enter the password</p>
      <div className="numbers">
        {[...Array(9)].map((_, index) => {
          
          return (
            <div
              key={index}
              className={`number ${clickedNumber === index ? "grow" : ""}`}
              onClick={() => handleNumberClick(index)}
            >
              {index + 1}
            </div>
          );
        })}
        <div
          className={`number    ${clickedNumber === "Clear" ? "grow" : ""}`}
          onClick={handleClearClick}
        >
          Clear
        </div>
        <div
          className={`number ${clickedNumber === 9 ? "grow" : ""}`}
          onClick={() => handleNumberClick(9)}
        >
          0
        </div>

        <div
          className={`number ${clickedNumber === "Enter" ? "grow" : ""}`}
          onClick={handleEnterClick}
        >
          Enter
        </div>
      </div>
    </div>
  );
}

export default PassCode;

