/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import Spinner from './Spinner';





// Use lazy to dynamically import components
const RetailPage = lazy(() => import("../components/retail/RetailPage"));
const InvoiceRefundTab = lazy(() => import("../components/retail/InvoiceRefundTab"));
const Report = lazy(() => import("../components/retail/Report/Report"));
const General = lazy(() => import("../components/Setting.jsx/General"));
const Viewinvoice = lazy(() => import("../components/retail/RetailInvoice/Viewinvoice"));
const SessionDaybook = lazy(() => import("../components/Dashboard/SessionDaybook"));
const BarcodePrint = lazy(() => import("../components/retail/Barcode/BarcodePrint"));
const VatSaleReport = lazy(() => import("../Report/IRD/VatSaleReport"));
const PassCode = lazy(() => import("./PassCode"));
const SalesReport = lazy(() => import("../Report/Sales/SalesReport"));
const SalesPaymentMethod = lazy(() => import('../Report/Sales/SalesPaymentMethod'));
const VatPurchaseReport = lazy(() => import('../Report/IRD/VatPurchaseReport'));



const SalesReportByDate = lazy(() => import("../Report/Sales/SalesReportByDate"));
const EmployeSaleReport = lazy(() => import("../Report/Sales/EmployeSaleReport"));
const SalesMaterializedReport = lazy(() => import("../Report/IRD/SalesMaterializedReport"));
const SalesReturn = lazy(() => import("../Report/IRD/SalesReturn"));
const SaleCategoryWise = lazy(() => import("../Report/Sales/SaleCategoryWise"));
const SaleItemWise = lazy(() => import("../Report/Sales/SaleItemWise"));
const SalesMonthly = lazy(() => import("../Report/Sales/SalesMonthly"));
const CustomerSaleReport = lazy(() => import("../Report/Sales/CustomerSaleReport"));
const SalesReportView = lazy(() => import("../Report/Sales/SalesReportView"));
const DayBook = lazy(() => import("../Report/Register/DayBook"));
const CashDrop = lazy(() => import("../Report/Register/CashDrop"));
const Opening = lazy(() => import("../Report/Register/Opening"));
const StockWarehouse = lazy(() => import("../Report/Inventory/StockWarehouse"));
const Login = lazy(() => import("../components/Login/Login"));
const AllDayendSummary = lazy(() => import("../components/Dashboard/AllDayendSummary"));
const Master = lazy(() => import("../components/Item master/Master"));
const AnalysisDashboard = lazy(() => import("../components/Analysis Dashboard/AnalysisDashboard"));
const Home = lazy(() => import("../components/Dashboard/Home"));
const Loyality = lazy(() => import("../components/Loyality/Loyality"));


const routes = [
  {
    path: "/",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Home/></Suspense>,
    exact: true,
  },
  {
    path: "/login",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Login/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/page",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><RetailPage/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/invoices",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><InvoiceRefundTab/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/Report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Report/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/master",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Master/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/invoices1",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><General/></Suspense>,
    exact: true,
  },
  {
    path: "/retails/viewinvoice/:id",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Viewinvoice/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/session/detail",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SessionDaybook/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/barcode",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><BarcodePrint/></Suspense>,
    exact: true,
  },
 
  {
    path: "/retail/vatsalereport",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><VatSaleReport/></Suspense>,
    exact: true,
  },
  {
    path: "/reports/vatpurchasereport",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><VatPurchaseReport/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/pass",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><PassCode/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/salesreport",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesReport/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/salesbydate",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesReportByDate/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/user/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><EmployeSaleReport/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/materialized/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesMaterializedReport/></Suspense>,
    exact: true,
  },
  {
    path: "/reports/salesreport",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesReturn/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/category/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SaleCategoryWise/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/item/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SaleItemWise/></Suspense>,
    exact: true,
  },

  {
    path: "/sales/paymentmethod",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesPaymentMethod/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/monthly",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesMonthly/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/customer/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><CustomerSaleReport/></Suspense>,
    exact: true,
  },
  {
    path: "/sales/report/customer/:id",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><SalesReportView/></Suspense>,
    exact: true,
  },
  {
    path: "/reports/daybook/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><DayBook/></Suspense>,
    exact: true,
  },
  {
    path: "/reports/cashdrop/detailreport",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><CashDrop/></Suspense>,
    exact: true,
  },
  {
    path: "/reports/opening/detailreport",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Opening/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/warehouse/report",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><StockWarehouse/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/dayend/all",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><AllDayendSummary/></Suspense>,
    exact: true,
  },
  {
    path: "/retail/analysisDashboard",
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><AnalysisDashboard/></Suspense>,
    exact: true,
  },
  {
    path:'/retail/loyality',
  
    element: <Suspense fallback={<Spinner msg="Loading..."/>}><Loyality/></Suspense>,
    exact: true,
  }
];

export default routes;
