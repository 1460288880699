/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import  { useState } from 'react'
import PassCode from '../../utils/PassCode'
import './Login.css'



 const data=  [
    {
      "address1": "Kathmandu",
      "address2": "",
      "companyId": 50,
      "companyName": null,
      "contactNumber1": "9876543210",
      "contactNumber2": "",
      "country": "Nepal",
      "createdAt": "Wed, 20 Jul 2022 09:59:12 GMT",
      "createdBy": "test",
      "designation": "Programmer",
      "firstName": "admin",
      "gender": "F",
      "id": 49,
      "isCustomer": 0,
      "isLoginAllowed": 1,
      "isVendor": 0,
      "lastName": "",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "SM Tech",
      "username": "admin",
     
    },
    {
      "address1": "asd",
      "address2": "",
      "companyId": 1,
      "companyName": "Demo Smtech Pvt Ltd",
      "contactNumber1": "12334",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Wed, 13 Sep 2023 04:42:34 GMT",
      "createdBy": "test",
      "designation": "asd",
      "firstName": "testing",
      "gender": "M",
      "id": 228,
      "isCustomer": 0,
      "isLoginAllowed": 0,
      "isVendor": 0,
      "lastName": "testing",
      "middleName": "",
      "panNo": "123456789",
      "state": "",
      "userCompanyName": "",
      "username": "testing"
    },
    {
      "address1": "",
      "address2": "",
      "companyId": 65,
      "companyName": null,
      "contactNumber1": "1231313",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Fri, 30 Jun 2023 04:36:40 GMT",
      "createdBy": "test",
      "designation": "",
      "firstName": "",
      "gender": "M",
      "id": 229,
      "isCustomer": 0,
      "isLoginAllowed": 0,
      "isVendor": 0,
      "lastName": "",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "",
      "username": ""
    },
    {
      "address1": "ktm",
      "address2": "",
      "companyId": 1,
      "companyName": "Demo Smtech Pvt Ltd",
      "contactNumber1": "1212121212",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Thu, 23 Feb 2023 04:13:41 GMT",
      "createdBy": "test",
      "designation": "dr",
      "firstName": "demo",
      "gender": "M",
      "id": 231,
      "isCustomer": 0,
      "isLoginAllowed": 1,
      "isVendor": 0,
      "lastName": "demo",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "demo",
      "username": "demo"
    },
    
   
    {
      "address1": "tink",
      "address2": "",
      "companyId": 1,
      "companyName": "Demo Smtech Pvt Ltd",
      "contactNumber1": "123123123",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Tue, 19 Sep 2023 05:17:54 GMT",
      "createdBy": "test",
      "designation": "dev",
      "firstName": "ryu",
      "gender": "M",
      "id": 256,
      "isCustomer": 0,
      "isLoginAllowed": 0,
      "isVendor": 0,
      "lastName": "Stha",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "",
      "username": "ryu"
    },
    {
      "address1": "fdty",
      "address2": "",
      "companyId": 108,
      "companyName": null,
      "contactNumber1": "4654",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Tue, 13 Feb 2024 11:07:08 GMT",
      "createdBy": "admin",
      "designation": "dfgty",
      "firstName": "ftgyd",
      "gender": "M",
      "id": 262,
      "isCustomer": 0,
      "isLoginAllowed": 1,
      "isVendor": 0,
      "lastName": "ftu",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "",
      "username": "ftgyd"
    },
    {
      "address1": "ktm",
      "address2": "",
      "companyId": 1,
      "companyName": "Demo Smtech Pvt Ltd",
      "contactNumber1": "1213456788",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Thu, 07 Mar 2024 05:55:53 GMT",
      "createdBy": "admin",
      "designation": "ca",
      "firstName": "pawan",
      "gender": "M",
      "id": 264,
      "isCustomer": 0,
      "isLoginAllowed": 1,
      "isVendor": 0,
      "lastName": "rasaili",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "",
      "username": "pawan"
    },
    {
      "address1": "tinkune",
      "address2": "",
      "companyId": 1,
      "companyName": "Demo Smtech Pvt Ltd",
      "contactNumber1": "456123",
      "contactNumber2": "",
      "country": "",
      "createdAt": "Tue, 14 May 2024 04:56:22 GMT",
      "createdBy": "admin",
      "designation": "staff",
      "firstName": "kamal",
      "gender": "M",
      "id": 285,
      "isCustomer": 0,
      "isLoginAllowed": 1,
      "isVendor": 0,
      "lastName": "katuwal",
      "middleName": "",
      "panNo": "",
      "state": "",
      "userCompanyName": "",
      "username": "kamal"
    }
  ]






  const Pin = (props) => {
    const [selectedUser, setSelectedUser] = useState(null);
  
    const handleCustomer = (user) => {
      setSelectedUser(user);
    };
  
    console.log(selectedUser);
    return (
      <div>
        <section>
          <div className="avatars">
            {data.filter((x) => x.isLoginAllowed === 1).map((i) => {
              return (
                <div
                  key={i.id}
                  className={`avatar-list ${selectedUser?.id === i.id ? 'selected' : ''}`}
                  onClick={() => handleCustomer(i)}
                >
                  <img
                    className={`avatar ${selectedUser?.id === i.id ? 'selected' : ''}`}
                    src="https://i.pravatar.cc/200?img=14"
                    alt=""
                  />
                  <p className={`avatar-name ${selectedUser?.id === i.id ? 'selected' : ''}`}>
                    {i.firstName}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        <div>
          <PassCode />
        </div>
        <div
          type="button"
          className="signup-link text-white mt-2"
          onClick={() => props.setPin(false)}
        >
          Login with Password
        </div>
      </div>
    );
  };
  
  export default Pin;