

import {Navigate,Outlet} from "react-router-dom";

const  ProtectedRoute = ({children,...rest}) => {

    const token = localStorage.getItem("RETAIL_TOKEN")
 
    return (token?<Outlet /> :<Navigate to='/login'/>

    )
};

export default ProtectedRoute;
