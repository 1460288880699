/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import "./Login.css";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt, FaEye, FaEyeSlash } from "react-icons/fa";  // Import eye icons

import { config } from "../../utils/config";
import { Button } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SignUp from "./SignUp";
import Pin from "./Pin";

function Login(props) {
  const [signUp, setSignUp] = useState(false);
  const [pin, setPin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);  // State to toggle password visibility
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/");
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const image = document.getElementById("logo-image");
      if (window.innerWidth < 768) {
        image.style.display = "none";
      } else {
        image.style.display = "block";
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnSubmit = (evt) => {
    evt.preventDefault();
    props.setPos(false);
    const { username, password } = state;

    if (username === "" || password === "") {
      toast.warn("Please Fill The Fields");
    } else if (username === undefined || password === undefined) {
      toast.warn("Please Fill The Fields");
    } else {
      fetch(`${config}/Login/companyWise/api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 5000,
        body: JSON.stringify({
          username,
          password,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status_code === 200) {
            window.localStorage.setItem("user", username);
            props.setToken(data.msg.userData[0].token);
            props.setCompany(data.msg.userData[0]);
            window.localStorage.setItem("company", JSON.stringify(data.msg.userData[0]));
            goToDashboard();
          } else if (data.status_code === 400) {
            toast.warn(data.msg);
          }
        })
        .catch((err) => {});
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      handleOnSubmit(evt);
    }
  };

  return (
    <div className="_form-05-box span-0 m-0">
      <div className="row login-edit" style={{ height: "100vh" }}>
        <div className="sidebars col relative px-0">
          <div
            style={{ color: "white", paddingLeft: '10px' }}
            className="form-05-box-a w-100 h-100 position-relative d-flex flex-column align-items-center"
          >
            <div className="_form_05_logo mt-5">
              <h1 className="text-uppercase">Retail-Pos</h1>
              <span className="text-sm-center">
                Empowering Businesses, Simplifying Transactions.
              </span>
            </div>
            <div className="below position-absolute bottom-0">
              <div className="d-flex flex-column align-items-center justify-content-center text-red">
                <span className="text-uppercase font-sm">
                  Powered By Smart Tech Pvt.Ltd
                </span>
                <span>Tinkune, Kathmandu, Nepal</span>
                <span>
                  <IoIosMail color="green" size="25px" />
                  info@smtechme.com
                </span>
                <span>
                  <FaPhoneAlt color="green" size="20px" />
                  +977-9851186839
                </span>
                <span>
                  <span>Retail 1.0.0</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="leftBar"
          style={{
            backgroundColor: '#122C53',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "70%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {pin == false ? (
            <>
              {signUp == false ? (
                <div
                  className="sideform"
                  style={{
                    borderRadius: "20px",
                    width: "55%",
                    height: "62%",
                    backgroundColor: "#122C53",
                    marginBottom: "100px",
                  }}
                >
                  <>
                    <div className="main-header">
                      <h1 style={{ color: "white" }}>Login to your account</h1>
                    </div>

                    <div
                      className="form-group"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="w-75">
                        <label
                          htmlFor="username"
                          className="text-danger fw-bold"
                          style={{ alignItems: "center" }}
                        >
                          Username
                        </label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          placeholder="Username"
                          required=""
                          aria-required="true"
                          value={state.username}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}  // Added event handler
                        />
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{
                        width: "100%",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",  // Added for eye icon positioning
                      }}
                    >
                      <div className="w-75 position-relative">
                        <label className="text-danger fw-bold" htmlFor="password">
                          Password
                        </label>
                        <div className="input-container">
                          <input
                            type={showPassword ? "text" : "password"}  // Toggle input type
                            name="password"
                    
                            className="form-control"
                            placeholder="Enter Password"
                            required=""
                            aria-required="true"
                            value={state.password}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}  // Added event handler
                            onCopy={(e) => e.preventDefault()}  // Disable copy
                            onCut={(e) => e.preventDefault()}   // Disable cut
                            onPaste={(e) => e.preventDefault()} // Disable paste
                            onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu
                          />
                          <span
                            className="password-toggle-icon"
                            onClick={() => setShowPassword(!showPassword)}  // Toggle showPassword state
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              color: "#aaa",
                              paddingTop:'15px'
                            }}
                          >
                            {showPassword ? <FaEyeSlash size={'20px'} /> : <FaEye  size={'20px'}/>}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{
                        width: "100%",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        padding: '0px'
                      }}
                    >
                      <Button
                        className="login-button btn btn-success w-75"
                        onClick={handleOnSubmit}
                      >
                        Login
                      </Button>
                    </div>
                    <div onClick={() => setSignUp(true)} className="signup-link text-white">
                      Not a member ? <a href="#">Signup now</a>
                    </div>
                    <div className="signup-link text-white" onClick={() => setPin(true)}>
                      Login with PIN
                    </div>
                  </>
                </div>
              ) : (
                <SignUp setSignUp={setSignUp} />
              )}
            </>
          ) : (
            <Pin setPin={setPin} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
