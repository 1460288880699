import {createContext} from "react";


const UserSessionContext = createContext({
    token:"",
    user:"",
  
    setToken: () => {}
});

export default UserSessionContext;