// icons svg images
import alert from "../assets/icons/alert.svg";
import bell from "../assets/icons/bell.svg";
import bills from "../assets/icons/bills.svg";
import budget from "../assets/icons/budget.svg";
import card from "../assets/icons/card.svg";
import check from "../assets/icons/check.svg";
import empty_check from "../assets/icons/empty_check.svg";
import gears from "../assets/icons/gears.svg";
import home from "../assets/icons/home.svg";
import menu from "../assets/icons/menu.svg";
import plane from "../assets/icons/plane.svg";
import plus from "../assets/icons/plus.svg";
import report from "../assets/icons/report.svg";
import search from "../assets/icons/search.svg";
import user from "../assets/icons/user.svg";
import wallet from "../assets/icons/wallet.svg";
import wealth from "../assets/icons/wealth.svg";
import cart from "../assets/icons/cart.svg";
import cardsi  from "../assets/icons/totasales.gif"
import refunds  from "../assets/icons/cashback.gif"
import price  from "../assets/icons/pricetag1.gif"
import revenue  from "../assets/icons/revenue.gif"
import settings  from "../assets/icons/settings.gif"
import poss  from "../assets/icons/poss.gif"
import reports  from "../assets/icons/reports.gif"
import homes  from "../assets/icons/homes.gif"
import inventory  from "../assets/icons/inventroy.gif"
import man  from "../assets/icons/man.gif"
import control  from "../assets/icons/control.gif"
import SearchBlue from "../assets/icons/search_blue.svg";
import barcode from "../assets/icons/barcode.svg";
import NotificationOrange from "../assets/icons/notification_orange.svg";
import ChevronDownDark from "../assets/icons/chevron_down_dark.svg";
import debit from "../assets/icons/card.png";
import money from "../assets/icons/money.png";
import wallets from "../assets/icons/wallet.png";
import coupon from "../assets/icons/coupon.webp";
import note from "../assets/icons/note.jpg";



import ExportDark from "../assets/icons/export_dark.svg";
import CardSales from "../assets/icons/card_sales.svg";
import SignOut from "../assets/icons/sign_out.svg";
import CardOrder from "../assets/icons/card_order.svg";
import Message from "../assets/icons/message.svg";
import sound from "../assets/icons/clickSound.mp3";
import Analytics from "../assets/icons/analytics.svg";
import Bag from "../assets/icons/bag.svg";
import Cart from "../assets/icons/cart.svg";
import Chart from "../assets/icons/chart.svg";
import Cog from "../assets/icons/cog.svg";
import CardProduct from "../assets/icons/card_product.svg";
import CardCustomer from "../assets/icons/card_customer.svg";
import BagGreen from "../assets/icons/bag_green.svg";
import TicketYellow from "../assets/icons/ticket_yellow.svg";
// person images
import avatar from "../assets/images/avatar.svg";


export const iconsImgs = {
  alert,SearchBlue ,BagGreen,TicketYellow,CardCustomer,Cog,
  Chart,CardProduct,Cart,Bag,ExportDark,Analytics,Message,SignOut,CardSales,CardOrder,cardsi,refunds,price,revenue,
  bell,NotificationOrange,ChevronDownDark,barcode,
  bills,
  budget,
  card,
  cart,
  check,
  empty_check,
  gears,
  home,
  menu,
  plane,
  plus,
  report,
  search,
  user,
  wallet,
  wealth,
  settings,
  poss,
  reports,
  homes,inventory,man,control,sound,debit,money,wallets,coupon,note

};

export const personsImgs = {
avatar

};
